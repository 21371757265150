import React, { useState, useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline';
import ReactGA from 'react-ga';
import { Footer, Header } from 'cev-components';
import {
  Inicio,
  Contacto,
  NotFound,
  Servicios,
  Nosotros,
  PoliticasDePrivacidad,
  Gracias,
  CalendarioTributario,
} from '../pages';
import Assets from '../assets';
import Configs from '../configs';
import 'typeface-open-sans';
import '../App.css';

const App = () => {
  ReactGA.initialize(Configs.idGoogleTagManager);

  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location.pathname]);

  return (
    <CssBaseline>
      <Header
        headerLogo={Assets.HeaderLogo}
        title={Configs.name}
        backgroundColor={Configs.colorPrimary}
        menuItems={Configs.menuItems}
        anchor={useState(null)}
        resize={useState(null)}
      />
      <Switch>
        <Route exact path="/" component={Inicio} />
        <Route path="/servicios/:key" component={Servicios} />
        <Route exact path="/servicios" component={Servicios} />
        <Route exact path="/nosotros" component={Nosotros} />
        <Route
          exact
          path="/calendario/tributario"
          component={CalendarioTributario}
        />
        <Route exact path="/politicas" component={PoliticasDePrivacidad} />
        <Route exact path="/contacto" component={Contacto} />
        <Route exact path="/contacto/gracias" component={Gracias} />
        <Route component={NotFound} />
      </Switch>
      <Footer
        facebookIframe={Configs.facebookIframe}
        texts={[Configs.location, Configs.email, Configs.phone]}
        rrss={Configs.rrss}
        name={Configs.name}
        description={Configs.description}
        downBackgroundColor={Configs.colorSecondary}
      />
    </CssBaseline>
  );
};

export default App;
