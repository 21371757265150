import React from 'react';
import { Presentation } from 'cev-components';

const NotFound = () => (
  <div>
    <Presentation title="Página no encontrada" />
  </div>
);

export default NotFound;
