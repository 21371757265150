import React, { useState, useEffect } from 'react';
import { Grid, Container, Typography } from '@material-ui/core';
import Calendar from 'react-calendar';
import { Helmet } from 'react-helmet';
import { Division } from 'cev-components';
import { connect } from 'react-redux';
import 'react-calendar/dist/Calendar.css';
import './style.css';
import { getDetailTaxesByDateAction } from '../../actions';
import ItemCalendar from './ItemCalendar';

const CalendarioTributario = ({
  calendar,
  searchType,
  getDetailTaxesByDate,
}) => {
  const [selected, setSelected] = useState({
    date: new Date(),
  });

  const handleChange = (date) => {
    setSelected({ date });
    getDetailTaxesByDate({ date });
  };

  const handleActiveStartDateChange = ({ activeStartDate }) => {
    setSelected({ date: activeStartDate });
    getDetailTaxesByDate({ date: activeStartDate, searchType: 'month' });
  };

  /* eslint-disable */
  useEffect(() => {
    getDetailTaxesByDate({ date: new Date() });
  }, []);

  return (
    <>
      <Helmet>
        <meta name="description" content="Calendario tributario" />
        <meta property="og:description" content="Calendario tributario" />
      </Helmet>

      <Container>
        <Grid container spacing={0} alignItems="center" direction="column">
          <Division title="Calendario Tributario" isMain />
        </Grid>

        <Grid container spacing={2} direction="row">
          <Grid item xs={12} sm={6}>
            <Calendar
              onChange={handleChange}
              onActiveStartDateChange={handleActiveStartDateChange}
              value={selected.date}
              view="month"
              minDate={new Date()}
              maxDate={new Date(2020, 6, 31)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={0} alignItems="center" direction="column">
              {calendar && calendar.length > 0 ? (
                <>
                  {calendar.map((item, key) => (
                    <Grid item key={key}>
                      <ItemCalendar
                        date={item.date}
                        title={item.title}
                        subtitle={item.subtitle}
                        list={item.list}
                      />
                    </Grid>
                  ))}
                  <Grid item>
                    Notas: <br />
                    (1) PEC : Pago con mandato al banco con cargo en la cuenta
                    corriente. <br />
                    (2) PEL : Pago en línea con cargo a la cuenta corriente,
                    cuenta vista, chequera electrónica o tarjeta de crédito
                    (algunas tarjetas aplican un cargo inmediato de intereses a
                    partir del día de la transacción).
                  </Grid>
                </>
              ) : (
                <Grid item xs={10} sm={8}>
                  <Typography variant="subtitle1">
                    {searchType === 'month'
                      ? 'Sin datos para el mes seleccionado'
                      : 'Sin datos para la fecha seleccionada'}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    calendar: state.calendar,
    searchType: state.searchType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDetailTaxesByDate: (payload) =>
      dispatch(getDetailTaxesByDateAction(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarioTributario);
