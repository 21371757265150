export default {
  font: '"Myriad-Pro", "Open sans", "Helvetica", "Arial", sans-serif',
  colorPrimary: '#0054a1',
  colorSecondary: '#007dc3',
  name: 'Lizana y Asociados',
  description: 'Gestión tributaria y contable',
  type: 'website',
  url: 'https://lizanayasociados.cl',
  location: 'Manquehue Sur 520, Oficina 205, Las Condes',
  email: 'jenifer.lizana@lizanayasociados.cl',
  phone: '+56 9 9044 9825',
  idGoogleTagManager: 'UA-145385897-1',
  facebookIframe:
    'https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Flizanayasociados%2F&tabs=timeline&width=340&height=70&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=384825358773638',
  rrss: [
    { name: 'facebook', link: 'https://www.facebook.com/lizanayasociados' },
    {
      name: 'linkedin',
      link: 'https://www.linkedin.com/in/jenifer-lizana-contreras-96203153/',
    },
  ],
  menuItems: [
    {
      path: '/servicios',
      title: 'Servicios',
      key: 'menu-servicios',
      icon: 'work',
    },
    {
      path: '/nosotros',
      title: 'Nosotros',
      key: 'menu-nosotros',
      icon: 'group',
    },
    {
      path: '/calendario/tributario',
      title: 'Calendario tributario',
      key: 'menu-calendario-tributario',
      icon: 'event',
    },
    {
      path: '/contacto',
      title: 'Contáctanos',
      key: 'menu-contacto',
      icon: 'email',
    },
  ],
};
