const isValidDate = (date) => date instanceof Date && !Number.isNaN(date);

const getFormatDate = (date) => {
  let month = `${date.getMonth() + 1}`;
  let day = `${date.getDate()}`;
  const year = date.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
};

export default {
  isValidDate,
  getFormatDate,
};
