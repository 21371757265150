export default {
  title: 'Servicios',
  description:
    'Nos enfocamos en materias contables, tributarias, auditorías y asesorías. Somos una empresa que busca dar apoyo a las pymes. Asesoramos y brindamos información para la toma de decisiones a grandes empresas.',
  sections: [
    {
      key: 'auditoria',
      title: 'Auditoría',
      // image: Assets.InicioBackground,
      // youtubeId: 'y22nGdqCwqs',
      // instagramId: 'BzcOOiFgLKn',
      description:
        'Prestamos servicios de Auditoría de diversos tipos, tales como Auditoría de Estados Financieros, Auditoría Interna, Auditoría a Edificios y Condominios, Auditorías Especiales.',
      sections: [
        {
          key: 'auditoria-estados-financieros',
          title: 'Auditoría de estados financieros',
          description:
            'Consiste en expresar una opinión sobre los estados financieros a base de nuestra auditoría. Efectuamos nuestras auditorías de acuerdo con Normas de Auditoría Generalmente Aceptadas en Chile. Tales normas requieren que planifiquemos y realicemos nuestro trabajo con el objeto de lograr un razonable grado de seguridad de que los estados financieros están exentos de representaciones incorrectas significativas. Para ello nos enfocamos en realizar un correcto entendimiento del negocio, sus procedimientos y normas, identificando los puntos críticos y dándolos a conocer a nuestros clientes mediante un informe de control interno y recomendaciones.',
        },
        {
          key: 'auditoria-y-revisiones-especiales',
          title: 'Auditoría y revisiones especiales',
          description:
            'Lizana y Asociados está enfocado en entregar un servicio íntegro ofreciendo soluciones en los siguientes procesos:',
          items: [
            {
              key: '1',
              description:
                'Revisiones especiales de acuerdo con procedimientos previamente acordados.',
            },
            {
              key: '2',
              description:
                'Revisión de controles de inventarios y activos fijos.',
            },
            { key: '3', description: 'Auditoría de sistemas informáticos.' },
          ],
        },
        {
          key: 'auditoria-interna',
          title: 'Auditoría interna',
          description:
            'La auditoría interna es un sistema de control interno de la empresa y consiste en el conjunto de medidas, políticas y procedimientos establecidos en una organización concreta para: proteger su activo, minimizar riesgos, incrementar la eficacia de los procesos operativos, optimizar y rentabilizar en definitiva el negocio.\nLa auditoría interna permite llevar a cabo un seguimiento actualizado de la gestión de un negocio, así como un método de control de las gestiones financieras.\nUna auditoría interna se debe hacer en base a un plan previamente redactado y diseñado, en función de las políticas y procedimientos de la empresa en cuestión. Asimismo, la auditoría interna va encaminada hacia el cumplimiento de los siguientes puntos:',
          items: [
            {
              description:
                'En qué medida se cumplen los planes y procedimientos derivados de dirección.',
            },
            {
              description:
                'Revisión y evaluación de la aplicación de los controles operativos, contables y financieros.',
            },
            {
              description:
                'Realizar un control de inventarios para que todos los bienes que la empresa posee estén registrados, protegidos y sujetos a la normativa pertinente.',
            },
            {
              description:
                'Verificar y evaluar la información contable, que ésta sea veraz y responda a la realidad económica de la empresa.',
            },
            {
              description:
                'Realización de investigaciones especiales y extraordinarias que la dirección exija.',
            },
            {
              description:
                'Preparación de informes de auditoría sobre las irregularidades que se pueden encontrar a término de las investigaciones, determinando asimismo posibles recomendaciones para solventar aquéllas.',
            },
            {
              description:
                'Vigilancia del cumplimiento de las recomendaciones determinadas en informes y auditorías.',
            },
          ],
        },
      ],
      path: '/servicios/auditoria',
    },
    {
      key: 'tributaria',
      title: 'Consultoría tributaria',
      description:
        'Te brindamos asistencia en materia tributaria para el correcto cumplimiento de la normativa vigente, respondemos tus preguntas, tramitamos RAV, recuperaciones de IVA, revisión de correcto cumplimiento de impuestos, traspaso de registros FUT, FUF, FUNT, FUR a nuevos registros empresariales, entre otras.',
      path: '/servicios/tributaria',
    },
    {
      key: 'contabilidad',
      title: 'Contabilidad',
      description:
        'Lizana y Asociados no solo se preocupa de llevar de manera correcta y ordenada los debidos registros para el cumplimiento de las normas chilenas, sino que también busca ser un apoyo constante a la empresa brindando asesoría para el crecimiento y consolidación de su empresa.',
      path: '/servicios/contabilidad',
    },
    {
      key: 'rrhh',
      title: 'Recursos Humanos',
      description:
        'Nos hacemos cargo de tu proceso de remuneraciones desde el punto cero, esto implica los debidos contratos, anexos, liquidacion, actualización de información, control de vacaciones, finiquitos, carga de planillas a PreviRed u otro medio, declaraciones juradas y busca de personal en caso de requerirlo.',
      path: '/servicios/rrhh',
    },
    {
      key: 'impuestos',
      title: 'Impuestos',
      description:
        'Nos preocupamos de cumplir con la correcta tributación de los impuestos personales y empresariales, confeccionamos declaraciones juradas y certificados, confección de nuevos registros empresariales, declaración formulario 29, declaración formulario 50.',
      path: '/servicios/impuestos',
    },
  ],
};
