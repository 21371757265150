import React from 'react';
import { Grid, Container } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { Presentation, Division } from 'cev-components';
import data from './text';
import servicios from '../Servicios/texts';

const Inicio = () => {
  return (
    <>
      <Helmet>
        <meta name="description" content={servicios.description} />
        <meta property="og:description" content={servicios.description} />
      </Helmet>

      <Presentation title={data.title} image={data.image} />

      <Container>
        <Grid container spacing={0} alignItems="center" direction="column">
          <Grid item xs={10} sm={8}>
            <Division
              title={servicios.title}
              description={servicios.description}
              key={servicios.key}
              isMain
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          {servicios.sections.map((section) => (
            <Grid item xs={12} sm={4} key={section.key}>
              <Division
                title={section.title}
                description={section.description}
                path={section.path}
                key={section.key}
                image={section.image}
                youtubeId={section.youtubeId}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};
export default Inicio;
