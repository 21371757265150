import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { Division } from 'cev-components';

const Gracias = () => {
  return (
    <Container>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={4}
      >
        <Grid item xs={12}>
          <Division
            title="¡Gracias!"
            description="Te contactaremos a la brevedad"
            isMain
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Gracias;
