import utils from '../utils';
import calendariotributario from '../api/calendariotributario';

const getDetailTaxesByDate = ({ date, searchType = 'day' }) => {
  if (!date || !utils.isValidDate(date)) return [];
  const filterDates = calendariotributario.filter((element) => {
    switch (searchType) {
      case 'year':
        return (
          element.date.substring(0, 4) ===
          utils.getFormatDate(date).substring(0, 4)
        );
      case 'month':
        return (
          element.date.substring(0, 7) ===
          utils.getFormatDate(date).substring(0, 7)
        );
      case 'day':
      default:
        return element.date === utils.getFormatDate(date);
    }
  });
  return filterDates;
};

const reducers = (state = {}, action) => {
  switch (action.type) {
    case 'GET_DETAIL_TAXES_BY_DATE':
      return {
        ...state,
        calendar: getDetailTaxesByDate(action.payload),
        searchType: action.payload.searchType,
      };
    default:
      return state;
  }
};

export default reducers;
