import React from 'react';
import Assets from '../../assets';

export default {
  nosotros: {
    title: 'Sobre nosotros',
    image: 'assets/img/bg5.jpg',
    description:
      'Lizana y Asociados nace con el compromiso de entregar a sus clientes, colaboradores y asociados una relación de confianza, compromiso, experiencia y profesionalismo.',
    mve: [
      {
        key: 'mision',
        title: 'Misión',
        icon: 'account_balance',
        description:
          'Como empresa consultora, los esfuerzos de la compañía están enfocados en estar siempre en conocimiento de la nuevas normativas nacionales, disposiciones legales, laborales y tributaria vigentes.',
      },
      {
        key: 'vision',
        title: 'Visión',
        icon: 'visibility',
        description:
          'Ser reconocidos por las empresas por entregar la mejor calidad en asesoría y gestión. Haciéndola con el paso de los años una de las firmas nacionales más destacadas.',
      },
      {
        key: 'estrategia',
        title: 'Estrategia',
        icon: 'trending_up',
        description:
          'Crecer de manera sostenida y rentable, dando solidez a la firma. Nuestras prioridades estratégicas:',
        items: [
          { description: 'Capacitación permanente.', key: 0 },
          { description: 'Innovación constante.', key: 1 },
          { description: 'Calidad de servicios.', key: 2 },
        ],
      },
    ],
  },
  team: {
    title: 'Nuestro Equipo',
    sections: [
      {
        key: 'JLC',
        title: 'Jenifer Lizana Contreras',
        avatar: Assets.AvatarJLC,
        rol: ['Socia Fundadora'],
        description:
          'Contador Auditor AIEP, Diplomado en planificación tributaria IEDE Business School, con más de 10 años de experiencia de ejercicio profesional en Asesorías, Auditoría de Estados Financieros, Control Interno, Contabilidad y Tributaria, participando además de diversos seminarios de actualización profesional tales como Impuesto a la Renta, Impuesto al Valor Agregado, Régimen Atribuido, Régimen Semi-intregado y de Normativa Previsional y Laboral.',
      },
      {
        key: 'CRV',
        title: 'Claudio Reyes Vidal',
        avatar: Assets.AvatarCRV,
        rol: ['Asociado', <br />, 'LAROS Consultores'],
        description:
          'Contador Auditor, Postítulo y Magíster en Planificación y Gestión Tributaria de la Universidad de Santiago de Chile, Diplomado en Normas de Contabilidad Internacional IFRS, de la Universidad de Chile.',
      },
    ],
  },
};
