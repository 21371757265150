import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Container,
  Grid,
  MenuItem,
  TextField,
  Box,
  Button,
  Icon,
} from '@material-ui/core';
import { Division } from 'cev-components';
import servicios from '../Servicios/texts';

const Contacto = () => {
  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
      )
      .join('&');
  };

  const defaultSubject = { key: 'seleccione', title: '- Seleccione -' };

  const [values, setValues] = useState({
    nombre: '',
    correo: '',
    telefono: '',
    mensaje: '',
  });

  const [toSuccess, setToSuccess] = useState(false);

  const [subject, setSubject] = useState(defaultSubject.key);

  const subjects = [
    defaultSubject,
    ...servicios.sections,
    {
      key: 'otro',
      title: 'Otro',
    },
  ];

  const handleChangeSubject = (event) => {
    setSubject(event.target.value);
  };

  const handleChange = (e) => {
    setValues({ ...values, ...{ [e.target.name]: e.target.value } });
  };

  const showError = () => {
    alert(
      'Hubo un error al enviar su formulario. Por favor contáctenos por correo o teléfono.'
    );
  };

  const handleSubmit = (e) => {
    const data = {
      subject,
      ...values,
    };
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contacto', ...data }),
    })
      .then((response) => {
        if (response.status !== 200) {
          showError();
        } else {
          setToSuccess(true);
        }
      })
      .catch(() => {
        showError();
      });
    e.preventDefault();
  };

  return toSuccess ? (
    <Redirect to="/contacto/gracias" />
  ) : (
    <Container>
      <form
        name="contacto"
        method="post"
        action="/contacto/gracias"
        netlify
        netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form-name" value="contacto" />
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={4}
        >
          <Grid item xs={12}>
            <Division title="Contáctenos" isMain />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="nombre"
              name="nombre"
              label="Nombre"
              variant="outlined"
              helperText="Name and lastname"
              value={values.nombre}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="correo"
              name="correo"
              label="Correo"
              variant="outlined"
              helperText="Email"
              value={values.correo}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="telefono"
              name="telefono"
              label="Teléfono"
              variant="outlined"
              helperText="Phone"
              value={values.telefono}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="asunto"
              name="asunto"
              select
              label="Asunto"
              value={subject}
              onChange={handleChangeSubject}
              helperText="Subject"
              variant="outlined"
              fullWidth
            >
              {subjects.map((item) => (
                <MenuItem
                  key={item.key}
                  value={item.key}
                  disabled={item.key === 'seleccione'}
                >
                  {item.title}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              id="mensaje"
              name="mensaje"
              label="¿Cómo te podemos ayudar?"
              multiline
              rows={6}
              variant="outlined"
              helperText="How can we help you?"
              value={values.mensaje}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box align="right">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                endIcon={<Icon>send</Icon>}
              >
                Enviar
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default Contacto;
