import React from 'react';
import { Grid, Container, Button } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Division } from 'cev-components';
import Texts from './texts';

const getServicio = (key) => {
  const servicios = Texts.sections.find((servicio) => servicio.key === key);
  return servicios || Texts;
};

const Servicios = (props) => {
  const {
    match: {
      params: { key },
    },
  } = props;
  const {
    title,
    description,
    sections,
    path,
    image,
    youtubeId,
    instagramId,
  } = getServicio(key);
  return (
    <Container>
      <Helmet>
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
      </Helmet>

      <Grid container spacing={0} alignItems="center" direction="column">
        <Grid item xs={10} sm={8}>
          <Division
            title={title}
            description={description}
            image={image}
            youtubeId={youtubeId}
            instagramId={instagramId}
            key={key}
            isMain
          />
        </Grid>
      </Grid>

      {sections && (
        <Grid container spacing={3}>
          {sections.map((section) => {
            return (
              <Grid item xs={12} sm={4} key={`grid${section.key}`}>
                <Division
                  title={section.title}
                  description={section.description}
                  path={section.path}
                  key={section.key}
                  items={section.items}
                />
              </Grid>
            );
          })}
        </Grid>
      )}

      {path && (
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={12} sm={5}>
            <Button
              component={Link}
              variant="contained"
              to="/servicios"
              color="secondary"
              startIcon={<NavigateBeforeIcon />}
            >
              Volver a Servicios
            </Button>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

Servicios.defaultProps = {
  match: {
    params: {
      key: '',
    },
  },
};

Servicios.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      key: PropTypes.string,
    }),
  }),
};

export default Servicios;
