import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from 'react-router-scroll-top';
import { ThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { CevMuiTheme } from 'cev-components';
import App from './routes/App';
import reducers from './reducers';
import * as serviceWorker from './serviceWorker';

import Configs from './configs';

const themeData = {
  font: Configs.font,
  colorPrimary: Configs.colorPrimary,
  colorSecondary: Configs.colorSecondary,
};

const store = createStore(reducers);

ReactDOM.render(
  <ThemeProvider theme={CevMuiTheme(themeData)}>
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </BrowserRouter>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
