import React from 'react';
import { Grid, Container } from '@material-ui/core';
import { Division } from 'cev-components';
import data from './text.json';

const PoliticasDePrivacidad = () => {
  return (
    <Container>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12}>
          <Division title={data.title} description={data.description} isMain />
        </Grid>
        {data.sections && (
          <Grid item xs={12} sm={8}>
            {data.sections.map((section) => (
              <Division
                title={section.title}
                description={section.description}
                // isMain
              />
            ))}
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default PoliticasDePrivacidad;
