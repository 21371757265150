const HeaderLogo = require('./header-logo.png');
const InicioBackground = require('./inicio-background.jpg');
const AvatarJLC = require('./avatar-jlc.jpg');
const AvatarCRV = require('./avatar-crv.jpg');

export default {
  HeaderLogo,
  InicioBackground,
  AvatarJLC,
  AvatarCRV,
};
