import React from 'react';
import { Grid, Container } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { Division } from 'cev-components';
import Texts from './texts';

const Nosotros = () => {
  const { nosotros, team } = Texts;
  return (
    <Container>
      <Helmet>
        <meta name="description" content={nosotros.description} />
        <meta property="og:description" content={nosotros.description} />
      </Helmet>
      <Grid container spacing={0} alignItems="center" direction="column">
        <Grid item xs={10} sm={8}>
          <Division
            title={nosotros.title}
            description={nosotros.description}
            isMain
          />
        </Grid>
        {nosotros.mve && (
          <Grid container direction="row" alignItems="baseline" spacing={3}>
            {nosotros.mve.map((item) => {
              return (
                <Grid item xs={12} sm={4} key={`grid${item.key}`}>
                  <Division
                    title={item.title}
                    description={item.description}
                    path={item.path}
                    key={item.key}
                    items={item.items}
                    icon={item.icon}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
        <Grid item xs={12}>
          <Division title={team.title} isMain />
          {team.sections && (
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              spacing={3}
            >
              {team.sections.map((item) => {
                return (
                  <Grid item xs={12} sm={5} key={`grid${item.key}`}>
                    <Division
                      title={item.title}
                      description={item.description}
                      items={item.items}
                      avatar={item.avatar}
                      subtitle={item.rol}
                      key={item.key}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Nosotros;
